<template>
  <!-- hero image for mobile and tablet -->
  <div
    :ref="'heroReveal' + index"
    v-for="(artist, index) in artists"
    :key="artist"
    class="artist-xs-hero-wrapper position-fixed top-0 left-0
    full-width-wrapper page-center-wrapper z-index-bottom"
  >
    <div
      :ref="'heroRevealInner' + index"
      class="hover-reveal__inner"
    >
      <img
        :ref="'scrollReveal' + index"
        :src="artist.Thumbnail.formats.medium.url"
        alt=""
        class="position-absolute w-100 h-100 img-object-fit
        top-0 left-0 hover-xs-reveal"
        @load="onImgLoad(index)"
      />
    </div>
  </div>
  <!-- End of hero image for mobile and tablet -->
  <div
    ref="artistNameContainer"
    class="
      artist-name-wrapper container-fluid page-height-content-wrapper
      overflow-x-xs-hidden
      pt-8 pt-lg-9
    "
  >
    <div
      class="wrapper mb-0 mb-xl-3"
    >
      <h1 class="text-uppercase">{{ title }} | {{ city[0].City }}</h1>
      <div class="body-regular text-uppercase">
        {{ city[0].SchoolName }}
      </div>
      <div class="body-regular text-uppercase mt-2">
        Curator(s):
      </div>
      <template
        v-if="!young"
      >
        <!-- Curator Name 1 -->
        <div class="body-regular text-uppercase">
          <a
            v-if="city[0].EXhibitionCuratorWebiste1"
            :href="city[0].EXhibitionCuratorWebiste1"
            target="_blank"
            class="text-decoration-none text-white d-flex align-items-center"
          >
            {{ city[0].EXhibitionCurator1 }}
            <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
          </a>
          <div v-else>
            {{ city[0].EXhibitionCurator1 }}
          </div>
        </div>
        <!-- Curator Name 1 -->

        <!-- Curator Name 2 -->
        <div v-if="city[0].EXhibitionCurator2" class="body-regular text-uppercase">
          <a
            v-if="city[0].EXhibitionCuratorWebiste2"
            :href="city[0].EXhibitionCuratorWebiste2"
            target="_blank"
            class="text-decoration-none text-white d-flex align-items-center"
          >
            {{ city[0].EXhibitionCurator2 }}
            <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
          </a>
          <div v-else>
            {{ city[0].EXhibitionCurator2 }}
          </div>
        </div>
        <!-- Curator Name 2 -->

        <!-- Curator Name 3 -->
        <div v-if="city[0].EXhibitionCurator3" class="body-regular text-uppercase">
          <a
            v-if="city[0].EXhibitionCuratorWebiste3"
            :href="city[0].EXhibitionCuratorWebiste3"
            target="_blank"
            class="text-decoration-none text-white d-flex align-items-center"
          >
            {{ city[0].EXhibitionCurator3 }}
            <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-2" />
          </a>
          <div v-else>
            {{ city[0].EXhibitionCurator3 }}
          </div>
        </div>
        <!-- Curator Name 3 -->

        <!-- Curator Name 4 -->
        <div v-if="city[0].EXhibitionCurator4" class="body-regular text-uppercase">
          <div>
            {{ city[0].EXhibitionCurator4 }}
          </div>
        </div>
        <!-- Curator Name 3 -->
      </template>
      <template
        v-else
      >
        <div class="body-regular text-uppercase">
          <div>
            {{ city[0].YoungCurrentCurator }}
          </div>
        </div>
      </template>
    </div>

    <!-- Artists List -->
    <transition-group
    tag="div"
    class="
      row g-0
      mt-xs-artist
    "
    appear @before-enter="beforeEnter" @enter="enter">
        <div
        v-for="(artist, index) in artists"
        :key="artist"
        :data-index="index"
        class="menu__wrapper col-12"
        :class="{
          top: revealImage && revealEnterIndex === index,
          'col-xl-6': artists.length > 6,
          'mb-xs-artist': sizeMode !== 'desktop' && index === artists.length - 1,
        }"
        >
          <a
            class="menu__item text-decoration-none text-uppercase"
            :class="{
              top: revealImage && revealEnterIndex === index,
              'text-white': !dayTime,
              'text-primary': dayTime,
            }"
            :ref="'menuItem' + index"
            @mouseover="mouseenterFn(index)"
            @mouseleave="mouseleaveFn(index)"
            @click="goToArtwork(index)"
          >
            <span class="menu__item-text">
              <span
                :ref="'menuText' + index"
                class="menu__item-textinner title-font mb-0 py-2 py-md-2 py-xl-1">
                {{ artist.Name }}
              </span>
            </span>
            <!-- Markup for the image, reveal on Hover -->
            <div
              class="hover-reveal d-none d-xl-block"
              :ref="'hoverReveal' + index"
            >
              <div class="hover-reveal__inner" :ref="'hoverRevealInner' + index">
                <div
                  class="hover-reveal__img"
                  :ref="'hoverRevealImg' + index"
                  :style="{ backgroundImage: 'url(' + artist.Thumbnail.formats.medium.url + ')' }"
                ></div>
              </div>
            </div>
            <!-- Markup for the image, reveal on Hover -->
          </a>
        </div>
    </transition-group>
    <!-- Artists List -->
  </div>
</template>

<script>
import gsap from 'gsap';
import { map, lerp, clamp } from '@/components/tools/utils';
import emitter from '@/components/tools/emitter';

export default {
  props: ['young', 'title', 'city', 'artists', 'slug'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      imgLoadLog: false,
      dayTime: null,
      animatableProperties: {
        tx: { previous: 0, current: 0, amt: 0.08 },
        ty: { previous: 0, current: 0, amt: 0.08 },
        rotation: { previous: 0, current: 0, amt: 0.08 },
        brightness: { previous: 1, current: 1, amt: 0.08 },
      },
      mousepos: {
        x: 0,
        y: 0,
      },
      mousePosCache: {},
      direction: {
        x: 0,
        y: 0,
      },
      firstRAFCycle: true,
      revealImage: false,
      revealEnterIndex: null,
      // desktopTextColor: '',
      sizes: {},
      sizeMode: '',
      /**
       * mobile effects
       */
      rwdScrollPos: 0,
      scrollDirection: '',
      initialTrigger: 100,
      triggerPoints: [],
      triggerIndex: null,
      textColor: '',
      highlightColor: '',
    };
  },
  watch: {
    city: {
      deep: true,
      handler() {
        for (let i = 0; i < this.artists.length; i += 1) {
          this.triggerPoints.push(this.initialTrigger + i * 55);
        }

        // Scroll listener after GraphQL response
        setTimeout(() => {
          this.$refs.artistNameContainer.addEventListener('scroll', this.onScroll);
        }, 200);
      },
    },
    sizeMode(n, o) {
      if (n !== o) {
        // console.log(this.sizeMode);
        if (this.sizeMode === 'desktop') {
          // reset text color
          for (let i = 0; i < this.artists.length; i += 1) {
            const menuTextLast = `menuText${i}`;
            gsap.to(this.$refs[menuTextLast], {
              transform: 'skewX(0)',
              color: this.dayTime ? 'black' : 'white',
              opacity: '1',
              duration: 0.8,
            });
          }
          /**
           * Hide last image
           */
          if (o !== null) {
            this.stopRendering();
            this.hideImage(this.triggerIndex);
          }
        }
      }
    },
    rwdScrollPos(n, o) {
      if (n < o) {
        this.scrollDirection = 'up';
      } else {
        this.scrollDirection = 'down';
      }
    },
    triggerIndex(n, o) {
      if (n !== o) {
        if (n <= this.artists.length) {
          // kill any current tweens
          const menuTextLast = `menuText${o}`;
          if (o !== null) {
            // reset text color
            if (o < this.artists.length - 1 || n === this.artists.length - 2) {
              gsap.to(this.$refs[menuTextLast], {
                transform: 'skewX(0)',
                color: `${this.textColor}`,
                duration: 0.8,
              });
            }

            // hide last text
            if (this.scrollDirection === 'down' && o < this.artists.length - 1) {
              gsap.to(this.$refs[menuTextLast], {
                opacity: '0',
                duration: 0.8,
              });
            }
            /**
             * Hide last image
             */
            if (o < this.artists.length - 1 || n === this.artists.length - 2) {
              this.stopRendering();
              // hide the image element
              this.hideImage(o);
            }
          } else {
            // reset all text color
            for (let i = 1; i < this.artists.length; i += 1) {
              const text = `menuText${i}`;
              gsap.to(this.$refs[text], {
                color: `${this.textColor}`,
              });
            }
          }

          /**
           * Twist selected artist text
           */
          const menuText = `menuText${n}`;
          // highlight text color
          gsap.to(this.$refs[menuText], {
            transform: 'skewX(-20deg)',
            color: `${this.highlightColor}`,
            duration: 0.8,
          });
          const hiddenText = `menuText${o - 1}`;
          if (this.scrollDirection === 'up') {
            gsap.to(this.$refs[hiddenText], {
              opacity: '1',
              duration: 0.8,
            });
          }

          /**
           * Render selected Artist Image
           */
          this.showImage(n);
          this.firstRAFCycle = true;
          this.loopRender(n);
        }
      }
    },
  },
  methods: {
    /**
     * Page Load effects
     */
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 2,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
      setTimeout(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
      }, 2000);
    },
    /**
     * Main methods
     */
    onImgLoad(index) {
      this.imgLoadLog.add(index);
      if (this.imgLoadLog.size === this.artists.length) {
        this.imgLoad = true;
        emitter.emit('imgLoadComplete');
      }
    },
    updatesizeMode() {
      // Update sizes
      this.sizes.width = window.innerWidth;
      this.sizes.height = window.innerHeight;
      if (this.sizes.width <= 575.99) {
        this.sizeMode = 'mobile';
      } else if (this.sizes.width <= 1199.99) {
        this.sizeMode = 'tablet';
      } else {
        this.sizeMode = 'desktop';
      }
    },
    reportWindowSize() {
      this.updatesizeMode();
    },
    beforeEnter(el) {
      // eslint-disable-next-line no-param-reassign
      el.style.opacity = 0;
      // eslint-disable-next-line no-param-reassign
      el.style.transform = 'translateY(100%)';
      // this.$refs.menuText.style.transform = 'translateY(100%)';
    },
    enter(el, done) {
      console.log('enter');
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 0.8,
        ease: 'Expo.easeOut',
        onComplete: done,
        delay: 1.5 + el.dataset.index * 0.06,
      });
    },
    getMousePos(event) {
      // this.mousepos.x = event.pageX;
      // this.mousepos.y = event.pageY;

      // get mouse position regardless of scrolling
      this.mousepos.x = event.clientX;
      this.mousepos.y = event.clientY;
    },
    onScroll() {
      this.rwdScrollPos = this.$refs.artistNameContainer.scrollTop;
      if (this.rwdScrollPos > 100) {
        this.triggerIndex = Math.floor((this.$refs.artistNameContainer.scrollTop - 100) / 55);
      }
    },
    // calculate the position/size of both the menu item and reveal element
    calcBounds(index) {
      const menuItem = `menuItem${index}`;
      const hoverReveal = `hoverReveal${index}`;
      this.bounds = {
        el: this.$refs[menuItem].getBoundingClientRect(),
        reveal: this.$refs[hoverReveal].getBoundingClientRect(),
      };
    },
    mouseenterFn(index) {
      if (this.sizeMode === 'desktop') {
        this.revealEnterIndex = index;
        this.showImage(index);
        this.firstRAFCycle = true;
        // start the render loop animation (rAF)
        this.loopRender(index);
      } else {
        // this.showImage(this.triggerIndex);
        // this.firstRAFCycle = true;
        // this.loopRender(this.triggerIndex);
      }
    },
    mouseleaveFn(index) {
      // stop the render loop animation (rAF)
      this.stopRendering();
      // hide the image element
      this.hideImage(index);
    },
    showImage(index) {
      let hoverRevealInner = '';
      let revealImage = '';
      let hoverReveal = '';
      if (this.sizeMode === 'desktop') {
        hoverRevealInner = `hoverRevealInner${index}`;
        revealImage = `hoverRevealImg${index}`;
        hoverReveal = `hoverReveal${index}`;
      } else {
        hoverRevealInner = `heroRevealInner${index}`;
        revealImage = `scrollReveal${index}`;
        hoverReveal = `heroReveal${index}`;
      }
      // kill any current tweens
      gsap.killTweensOf(this.$refs[hoverRevealInner]);
      gsap.killTweensOf(this.$refs[revealImage]);
      gsap.killTweensOf(this.$refs[hoverReveal]);
      if (this.sizeMode === 'desktop') {
        this.tl = gsap
          .timeline({
            onStart: () => {
              // show the image element
              this.revealImage = true;
              if (this.imgLoad) {
                this.$refs[hoverReveal].style.opacity = 1;
              }
            },
          })
          // animate the image wrap
          .to(this.$refs[hoverRevealInner], 0.2, {
            ease: 'Sine.easeOut',
            startAt: { x: this.direction.x < 0 ? '-100%' : '100%' },
            x: '0%',
          })
          // animate the image element
          .to(
            this.$refs[revealImage],
            0.2,
            {
              ease: 'Sine.easeOut',
              startAt: { x: this.direction.x < 0 ? '100%' : '-100%' },
              x: '0%',
            },
            0,
          );
      } else {
        this.tl = gsap
          .timeline({
            onStart: () => {
              // show the image element
              this.revealImage = true;
              if (this.imgLoad) {
                this.$refs[hoverReveal].style.opacity = 1;
              }
            },
          })
          // animate the image wrap
          .to(this.$refs[hoverRevealInner], 0.2, {
            ease: 'Sine.easeOut',
            startAt: { x: this.scrollDirection === 'up' ? '-100%' : '100%' },
            x: '0%',
          })
          // animate the image element
          .to(
            this.$refs[revealImage],
            0.2,
            {
              ease: 'Sine.easeOut',
              startAt: { x: this.scrollDirection === 'up' ? '100%' : '-100%' },
              x: '0%',
            },
            0,
          );
      }
    },
    // hide the image element
    hideImage(index) {
      // let hoverRevealInner = '';
      // let revealImage = '';
      // let hoverReveal = '';
      // if (this.sizeMode === 'desktop') {
      const hoverRevealInner = `hoverRevealInner${index}`;
      const revealImage = `hoverRevealImg${index}`;
      const hoverReveal = `hoverReveal${index}`;
      // } else {
      const mobileHoverRevealInner = `heroRevealInner${index}`;
      const mobileRevealImage = `scrollReveal${index}`;
      const mobileHoverReveal = `heroReveal${index}`;
      // }
      // kill any current tweens
      gsap.killTweensOf(this.$refs[hoverRevealInner]);
      gsap.killTweensOf(this.$refs[revealImage]);
      gsap.killTweensOf(this.$refs[hoverReveal]);
      gsap.killTweensOf(this.$refs[mobileHoverRevealInner]);
      gsap.killTweensOf(this.$refs[mobileRevealImage]);
      gsap.killTweensOf(this.$refs[mobileHoverReveal]);

      // if (this.sizeMode === 'desktop') {
      this.tl = gsap
        .timeline({
          onStart: () => {
            // reset z-index value
            // gsap.set(this.DOM.el, { zIndex: 1 });
          },
          onComplete: () => {
            // gsap.set(this.DOM.reveal, { opacity: 0 });
            this.revealImage = false;
            if (this.imgLoad) {
              this.$refs[hoverReveal].style.opacity = 0;
            }
          },
        })
        .to(this.$refs[hoverRevealInner], 0.2, {
          ease: 'Sine.easeOut',
          x: this.direction.x < 0 ? '100%' : '-100%',
        })
        .to(
          this.$refs[revealImage],
          0.2,
          {
            ease: 'Sine.easeOut',
            x: this.direction.x < 0 ? '-100%' : '100%',
          },
          0,
        );
      // } else {
      this.tl = gsap
        .timeline({
          onStart: () => {
          },
          onComplete: () => {
            this.revealImage = false;
            if (this.imgLoad) {
              this.$refs[mobileHoverReveal].style.opacity = 0;
            }
          },
        })
        .to(this.$refs[mobileHoverRevealInner], 0.2, {
          ease: 'Sine.easeOut',
          x: this.scrollDirection === 'up' ? '100%' : '-100%',
        })
        .to(
          this.$refs[mobileRevealImage],
          0.2,
          {
            ease: 'Sine.easeOut',
            x: this.scrollDirection === 'up' ? '-100%' : '100%',
          },
          0,
        );
      // }
      this.revealEnterIndex = null;
    },
    // start the render loop animation (rAF)
    loopRender() {
      if (!this.requestId) {
        this.requestId = requestAnimationFrame(() => {
          this.render(this.revealEnterIndex);
        });
      }
    },
    // stop the render loop animation (rAF)
    stopRendering() {
      if (this.requestId) {
        window.cancelAnimationFrame(this.requestId);
        this.requestId = undefined;
      }
    },
    render(index) {
      this.revealImage = true;
      this.requestId = undefined;

      if (this.sizeMode === 'desktop') {
        // calculate position/sizes the first time
        if (this.firstRAFCycle) {
          this.calcBounds(index);
        }

        // calculate the mouse distance (current vs previous cycle)
        const mouseDistanceX = clamp(Math.abs(this.mousePosCache.x - this.mousepos.x), 0, 100);
        // direction where the mouse is moving
        this.direction = {
          x: this.mousePosCache.x - this.mousepos.x,
          y: this.mousePosCache.y - this.mousepos.y,
        };
        // updated cache values
        this.mousePosCache = {
          x: this.mousepos.x,
          y: this.mousepos.y,
        };

        // new translation values
        // the center of the image element is positioned where the mouse is
        // eslint-disable-next-line max-len
        this.animatableProperties.tx.current = Math.abs(this.mousepos.x - this.bounds.el.left) - this.bounds.reveal.width / 2;
        // eslint-disable-next-line max-len
        this.animatableProperties.ty.current = Math.abs(this.mousepos.y - this.bounds.el.top) - this.bounds.reveal.height / 2;
        // new rotation value
        this.animatableProperties.rotation.current = this.firstRAFCycle
          ? 0
          : map(mouseDistanceX, 0, 100, 0, this.direction.x < 0 ? 60 : -60);
        // new filter value
        this.animatableProperties.brightness.current = this.firstRAFCycle
          ? 1
          : map(mouseDistanceX, 0, 100, 1, 4);

        // set up the interpolated values
        // for the first cycle, both the interpolated values need to be the same
        // so there's no "lerped" animation between the previous and current state
        this.animatableProperties.tx.previous = this.firstRAFCycle
          ? this.animatableProperties.tx.current
          : lerp(
            this.animatableProperties.tx.previous,
            this.animatableProperties.tx.current,
            this.animatableProperties.tx.amt,
          );
        this.animatableProperties.ty.previous = this.firstRAFCycle
          ? this.animatableProperties.ty.current
          : lerp(
            this.animatableProperties.ty.previous,
            this.animatableProperties.ty.current,
            this.animatableProperties.ty.amt,
          );
        this.animatableProperties.rotation.previous = this.firstRAFCycle
          ? this.animatableProperties.rotation.current
          : lerp(
            this.animatableProperties.rotation.previous,
            this.animatableProperties.rotation.current,
            this.animatableProperties.rotation.amt,
          );
        this.animatableProperties.brightness.previous = this.firstRAFCycle
          ? this.animatableProperties.brightness.current
          : lerp(
            this.animatableProperties.brightness.previous,
            this.animatableProperties.brightness.current,
            this.animatableProperties.brightness.amt,
          );

        // set styles
        const hoverReveal = `hoverReveal${index}`;
        this.animation = gsap.set(this.$refs[hoverReveal], {
          x: this.animatableProperties.tx.previous,
          y: this.animatableProperties.ty.previous,
          rotation: this.animatableProperties.rotation.previous,
          filter: `brightness(${this.animatableProperties.brightness.previous})`,
        });
        // gsap.set(this.$refs[hoverReveal], {
        //   x: this.animatableProperties.tx.previous,
        //   y: this.animatableProperties.ty.previous,
        //   rotation: this.animatableProperties.rotation.previous,
        //   filter: `brightness(${this.animatableProperties.brightness.previous})`,
        // });

        // loop
        this.firstRAFCycle = false;
      } else {
        // set styles
        const hoverReveal = `heroReveal${this.triggerIndex}`;
        this.animation = gsap.set(this.$refs[hoverReveal], {
          x: 0,
          y: 0,
        });
      }
      this.loopRender();
    },
    goToArtwork(index) {
      this.$router.push(`/city/${this.slug}/artist/${this.artists[index].Slug}`);
    },
  },
  mounted() {
    this.imgLoadLog = new Set();
    this.textColor = 'black';
    this.highlightColor = 'white';
    if (this.$store.state.time === 'day') {
      this.dayTime = true;
    } else {
      this.dayTime = false;
    }
    emitter.on('emit-day-night', (data) => {
      if (data === 'day') {
        this.dayTime = true;
      } else {
        this.dayTime = false;
      }
    });
    window.addEventListener('mousemove', this.getMousePos);
    this.mousePosCache = { ...this.mousepos };
    this.direction = {
      x: this.mousePosCache.x - this.mousepos.x,
      y: this.mousePosCache.y - this.mousepos.y,
    };
    window.addEventListener('resize', this.reportWindowSize);
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('overflow-hidden');
    this.updatesizeMode();
  },
  beforeUnmount() {
    this.$refs.artistNameContainer.removeEventListener('scroll', this.onScroll);
  },
  unmounted() {
    window.removeEventListener('mousemove', this.getMousePos);
    window.removeEventListener('resize', this.reportWindowSize);

    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('overflow-hidden');

    this.stopRendering();
    const hoverRevealInner = `hoverRevealInner${this.revealEnterIndex}`;
    const revealImage = `hoverRevealImg${this.revealEnterIndex}`;
    const hoverReveal = `hoverReveal${this.revealEnterIndex}`;
    gsap.killTweensOf(this.$refs[hoverRevealInner]);
    gsap.killTweensOf(this.$refs[revealImage]);
    gsap.killTweensOf(this.$refs[hoverReveal]);
  },
};
</script>
