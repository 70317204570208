// Map number x from range [a, b] to [c, d]
const map = (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c;

// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;

// eslint-disable-next-line no-nested-ternary
const clamp = (num, min, max) => (num <= min ? min : num >= max ? max : num);

export { map, lerp, clamp };
